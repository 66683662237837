import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Color } from '../../theme';
import { Text, devices, Spacing } from '../common';
import { RichTextContent } from '../StyledComponents';

type Props = {
  title: string;
  content: string;
  picture: { publicURL: string };
  boxColor: Color;
  reverse?: boolean;
  buttons?: ReactNode;
};

const GridBlock = ({
  boxColor,
  reverse,
  picture,
  title,
  content,
  buttons,
}: Props) => {
  return (
    <Container boxColor={boxColor}>
      <Picture src={picture.publicURL} />
      <Content position={reverse}>
        <Text variant="title-2">{title}</Text>
        <Spacing axis="y" amount="default" />
        <RichTextContent variant="body">{content}</RichTextContent>
        <Spacing axis="y" amount="medium" />
        {buttons}
      </Content>
    </Container>
  );
};

export default GridBlock;

const Container = styled.div<{ boxColor: Color }>`
  background-color: ${(p) => p.theme.colors[p.boxColor]};
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media ${devices.laptopXS} {
    grid-template-columns: 1fr;
  }
`;

const Picture = styled.img`
  width: 100%;
`;

const Content = styled.div<{ position?: boolean }>`
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 650px;
  order: ${(props) => (props.position ? '1' : '-1')};

  @media ${devices.laptopXS} {
    order: 1;
    padding: ${(props) => props.theme.spacing.medium} 0px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;
